import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { ComponentType, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ArrowDownIcon, ArrowUpIcon, CheckIcon, IconProps } from "../icons";
import { DataAttributes, ValidTailwindSizes } from "../types";
import { Typography } from "../typography";
import { convertDataAttributes } from "../utils";

export type SelectSearchSizeVariant = "SMALL" | "MEDIUM" | "REGULAR";

const SIZE_VARIANT_MAPS: Record<SelectSearchSizeVariant, string> = {
  SMALL: "px-2 rounded-sm h-7",
  MEDIUM: "px-2 rounded-sm h-8",
  REGULAR: "pl-4 pr-4 rounded h-10",
};

const TEXT_SIZE_VARIANT_MAPS: Record<SelectSearchSizeVariant, string> = {
  SMALL: "text-xs",
  MEDIUM: "text-xs",
  REGULAR: "text-sm",
};

const ERROR_SIZE_VARIANT_MAPS: Record<SelectSearchSizeVariant, string> = {
  SMALL: "text-2xs",
  MEDIUM: "text-2xs",
  REGULAR: "text-xs",
};

const WIDTH_VARIANT_MAPS: Record<SelectSearchSizeVariant, `w-${ValidTailwindSizes}`> = {
  SMALL: "w-40",
  MEDIUM: "w-40",
  REGULAR: "w-60",
};

// When refactor notice that this interface is used in DropdownSearch as well
export interface SelectSearchOption {
  label: ReactNode;
  value: string;
  disabled?: boolean;
}

export interface SearchableSelectSearchOption {
  label: string;
  value: string;
}

export type OnSelectSearechFilter = (
  query: string,
  options: SearchableSelectSearchOption[]
) => SearchableSelectSearchOption[];

export type SelectSearchProps = {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: SelectSearchSizeVariant;
  options: SelectSearchOption[];
  width?: `w-${ValidTailwindSizes}`;
  className?: string;
  dataAttributes?: DataAttributes;
  placeholder?: string;
  renderOption?: (option: SelectSearchOption) => JSX.Element;
  boxShadow?: boolean;
  variant?: "REGULAR" | "FILTER";
  onSelectSearchClick?: () => void;
  placeholderColor?: string;
  border?: boolean;
  iconProps?: IconProps;
  overrideDisabledStyle?: boolean;
  labelClassName?: string;
  biColorList?: boolean;
  leftIcon?: ComponentType<IconProps> | undefined;
  leftIconSize?: number;
  onFilter?: OnSelectSearechFilter;
};

export const getMultipleSelectSearchText = (value: ReactNode[]): ReactNode => {
  if (!value.length) {
    return "";
  }

  if (value.length <= 2) {
    return value.join(", ");
  }

  return (
    <FormattedMessage
      defaultMessage="{firstElement} and {restCount} more"
      values={{
        firstElement: value[0],
        restCount: value.length - 1,
      }}
      id="QYsAJ7"
    />
  );
};

const defaultFilterLogic = (
  query: string,
  allLabels: SearchableSelectSearchOption[]
): SearchableSelectSearchOption[] => {
  return allLabels.filter(
    (option) => option.label.toLowerCase().indexOf(query.toLowerCase()) !== -1
  );
};

export function SelectSearch({
  options,
  value,
  onChange,
  disabled,
  size = "REGULAR",
  error,
  errorMessage,
  width,
  className,
  dataAttributes = {},
  placeholder,
  renderOption,
  boxShadow,
  variant = "REGULAR",
  onSelectSearchClick,
  border = true,
  iconProps,
  overrideDisabledStyle,
  biColorList = true,
  onFilter = defaultFilterLogic,
}: SelectSearchProps) {
  const dataAttr = convertDataAttributes(dataAttributes);
  const [query, setQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const comboBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (inputRef.current && dropdownRef.current) {
      dropdownRef.current.style.minWidth = `${inputRef.current.offsetWidth}px`;
    }
  }, []);

  const filteredOptions = onFilter(
    query,
    options?.map((option) => ({ ...option, label: innerText(option.label) })) || []
  ).map((searchableOption) => options.find((option) => option.value === searchableOption.value)!);

  const simulateDropDownOpen = (open: boolean) => {
    if (!open) {
      comboBtn.current?.click();
    }
  };

  return (
    <Combobox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className={classNames("relative", width || WIDTH_VARIANT_MAPS[size])} {...dataAttr}>
            <Combobox.Input
              ref={inputRef}
              className={classNames(
                `text-left text-blueGray600 focus:outline-none focus:ring-1 flex w-full cursor-pointer items-center justify-between bg-white`,
                SIZE_VARIANT_MAPS[size],
                TEXT_SIZE_VARIANT_MAPS[size],
                className,
                {
                  border,
                  "text-blueGray900 bg-red10 border-red500 hover:border-red10 focus:border-red10 ring-red10":
                    error,
                  "border-blueGray200 hover:border-blueGray500 focus:border-primaryBlue500 ring-primaryBlue500":
                    !error && !disabled && !open,
                  "border-primaryBlue500 ring-1 ring-primaryBlue500": open,
                  "bg-blueGray50 border-blueGray200 cursor-not-allowed":
                    disabled && !overrideDisabledStyle,
                  "cursor-not-allowed": disabled && overrideDisabledStyle,
                  "shadow-1": boxShadow,
                  "bg-white": variant === "REGULAR",
                  "bg-blueGray25": variant === "FILTER",
                  "text-blueGray900": value,
                }
              )}
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(selectedItem: string) => {
                const option = options?.find((option) => option.value === selectedItem);
                return option ? innerText(option.label) : "";
              }}
              onFocus={() => simulateDropDownOpen(open)}
              aria-label="search"
              data-cy="select-search-input"
            />
            <Combobox.Button
              ref={comboBtn}
              onClick={onSelectSearchClick}
              className="absolute inset-y-0 right-0 flex items-center pr-2"
            >
              {open
                ? ArrowUpIcon && (
                    <ArrowUpIcon size={14} className="text-primaryBlue900" {...iconProps} />
                  )
                : ArrowDownIcon && (
                    <ArrowDownIcon size={14} className="text-primaryBlue900" {...iconProps} />
                  )}
            </Combobox.Button>
            {!value && !query && (
              <div
                className="absolute inset-y-0 left-0 flex items-center pl-4"
                onClick={() => {
                  inputRef.current?.focus();
                }}
              >
                <Typography fontSize="text-sm" color="text-blueGray600">
                  {placeholder}
                </Typography>
              </div>
            )}
            {error && errorMessage && (
              <div
                className={classNames(
                  `flex-1 mt-1 text-red500 truncate ${ERROR_SIZE_VARIANT_MAPS[size]}`
                )}
              >
                {errorMessage}
              </div>
            )}
          </div>
          <div
            className="absolute z-20 mt-1 w-auto min-w-max rounded-md bg-white text-left shadow-lg"
            ref={dropdownRef}
            data-cy="select-search-results"
          >
            <Transition
              show={open}
              leave="transition duration-100 ease-in"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                static
                className="border-blueGray200 max-h-56 overflow-auto rounded-md border text-base focus:outline-none sm:text-sm"
                data-cy="select-options"
              >
                {filteredOptions?.map((option, index) => (
                  <Combobox.Option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {({ active, selected, disabled }) => (
                      <div
                        className={classNames(
                          `flex relative justify-between items-center select-none ${SIZE_VARIANT_MAPS[size]} `,
                          {
                            [`bg-blueGray10 text-gray900`]: biColorList && index % 2 !== 0,
                            [`bg-white text-gray900`]: biColorList && index % 2 === 0,
                          },
                          !disabled ? "cursor-pointer" : "cursor-not-allowed"
                        )}
                      >
                        <div
                          className={classNames("flex flex-1 pr-4 truncate", {
                            [TEXT_SIZE_VARIANT_MAPS[size]]: size,
                            "text-blueGray600": !active && !selected && !disabled,
                            "text-primaryBlue500": active && !disabled,
                            "font-medium text-primaryBlue500": selected && !disabled,
                            "text-blueGray300": disabled,
                          })}
                        >
                          {typeof renderOption === "function" ? renderOption(option) : option.label}
                        </div>
                        {selected ? <CheckIcon size={16} className="text-primaryBlue500" /> : null}
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </>
      )}
    </Combobox>
  );
}

const hasProps = (jsx: ReactNode): jsx is ReactElement =>
  Object.prototype.hasOwnProperty.call(jsx, "props");

const reduceJsxToString = (previous: string, current: ReactNode): string =>
  previous + innerText(current);

const innerText = (jsx: ReactNode): string => {
  // Empty
  if (jsx === null || typeof jsx === "boolean" || typeof jsx === "undefined") {
    return "";
  }

  // Numeric children.
  if (typeof jsx === "number") {
    return jsx.toString();
  }

  // String literals.
  if (typeof jsx === "string") {
    return jsx;
  }

  // Array of JSX.
  if (Array.isArray(jsx)) {
    return jsx.reduce<string>(reduceJsxToString, "");
  }

  // Children prop.
  if (hasProps(jsx) && Object.prototype.hasOwnProperty.call(jsx.props, "children")) {
    return innerText(jsx.props.children);
  }

  // Default
  return "";
};
