import { useState } from "react";
import { ZodTypeAny } from "zod";

import { buildValidator, FormErrors } from "./formValidator";

export const useFormValidator = <T extends Record<string, ZodTypeAny>>(
  schema: T,
  onValidationCompleted: (success: boolean, errors: FormErrors) => void,
  initialFormErrors: FormErrors = {}
) => {
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);
  const validator = buildValidator(schema);

  const validateField = (key: keyof T, val: unknown) => {
    const { success, error } = validator.field(key, val);
    if (!success) {
      setFormErrors({ ...formErrors, [key]: error });
    } else {
      setFormErrors(Object.fromEntries(Object.entries(formErrors).filter(([k]) => k !== key)));
    }
  };

  const validate = (object: Record<keyof T, unknown>) => {
    const { success, errors } = validator.parse(object);
    onValidationCompleted(success, errors);
    setFormErrors(errors);
    return { success, errors };
  };

  return {
    formErrors,
    validate,
    validateField,
    reset: () => setFormErrors({}),
  };
};

export const parseError = (key: string, err: string[]) => `${key}: ${err.join(". ")}`;
