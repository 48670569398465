import { Input } from "../input";
import { Select, SelectOption } from "../select";
import { ValidTailwindSizes } from "../types";

export interface DropdownSearchOption<T = string> extends Omit<SelectOption, "value"> {
  placeholder?: string;
  value: T;
}

export interface DropdownSearchProps {
  options: DropdownSearchOption[];
  dropdownValue: string;
  inputValue: string;
  onDropdownChange: (value: string) => void;
  onInputChange: (value: string) => void;
  // Applied both to Input and Select components
  size?: "SMALL" | "REGULAR";
  inputPlaceholder?: string;
  width?: `w-${ValidTailwindSizes}`;
  onSelectClick?: () => void;
}

export const DropdownSearch = ({
  options,
  dropdownValue,
  onDropdownChange,
  inputValue,
  onInputChange,
  size = "SMALL",
  inputPlaceholder = "Search",
  width = "w-104",
  onSelectClick,
}: DropdownSearchProps) => {
  return (
    <div className={width}>
      <div className="flex">
        <Select
          dataAttributes={{ cy: "dropdown-search-select" }}
          className="h-full w-full rounded-r-none"
          size="MEDIUM"
          options={options}
          value={dropdownValue}
          onChange={onDropdownChange}
          variant="FILTER"
          onSelectClick={onSelectClick}
        />
        <Input
          data-cy="dropdown-search-input"
          className="-ml-px h-8 rounded-l-none"
          value={inputValue}
          onChange={(event) => onInputChange(event.target.value)}
          placeholder={inputPlaceholder}
          size={size}
          fluid
        />
      </div>
    </div>
  );
};
