import classNames from "classnames";
import { ReactNode, TextareaHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";

import { DataAttributes } from "../types";
import { Typography } from "../typography";
import { convertDataAttributes } from "../utils";

type SizeVariant = "EXTRA_SMALL" | "SMALL" | "REGULAR" | "LARGE";
type TextSizeVariant = "XS" | "SM" | "BASE" | "LG";
type ColorVariant = "DARK_BLUE" | "PRIMARY_BLUE_900" | "WHITE";
type StateVariant = "DEFAULT" | "ERROR";

const STATE_MAPS: Record<StateVariant, string> = {
  DEFAULT: "border border-blueGray200 focus:border-primaryBlue500",
  ERROR: "border bg-red10 border-red500 focus:border-red500 active:border-red500 ring-red10",
};

const SIZE_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "h-6",
  SMALL: "",
  REGULAR: "h-10",
  LARGE: "h-20",
};

const COLOR_MAPS: Record<ColorVariant, string> = {
  DARK_BLUE: "text-primaryBlue900",
  PRIMARY_BLUE_900: "text-primaryBlue900",
  WHITE: "text-white",
};

const TEXT_SIZE_MAPS: Record<TextSizeVariant, string> = {
  XS: "text-xs",
  SM: "text-sm",
  BASE: "text-base",
  LG: "text-lg",
};

export interface TextAreaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "size"> {
  color?: ColorVariant;
  textSize?: TextSizeVariant;
  state?: StateVariant;
  size?: SizeVariant;
  helper?: string;
  helperRenderer?: (helper: string) => ReactNode;
  rounded?: boolean;
  dataAttributes?: DataAttributes;
  disableErrorIcon?: boolean;
  errorMessage?: string;
  fluid?: boolean;
  name?: string;
  renderErrorContainer?: boolean;
  register?: UseFormRegister<any>;
  required?: boolean;
  placeholder: string; // Ensure placeholder is mandatory to meet a11y requirements
  monoFont?: boolean;
}

export function TextArea({
  rounded = true,
  size = "SMALL",
  state = "DEFAULT",
  dataAttributes = {},
  disableErrorIcon,
  errorMessage,
  fluid,
  name,
  register,
  required,
  renderErrorContainer,
  textSize = "SM",
  color = "DARK_BLUE",
  placeholder,
  monoFont,
  ...props
}: TextAreaProps) {
  const dataAttr = convertDataAttributes(dataAttributes);

  return (
    <span className="relative w-full">
      <div className="relative w-full">
        <div className={classNames("relative", fluid ? "w-full" : "w-min")}>
          <textarea
            rows={2}
            {...(register && name ? register(name, { required }) : {})}
            className={classNames(
              "pt-1 pl-4 leading-8 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
              STATE_MAPS[state],
              SIZE_MAPS[size],
              COLOR_MAPS[color],
              TEXT_SIZE_MAPS[textSize],
              { rounded, "w-full": fluid, "font-mono": monoFont }
            )}
            {...dataAttr}
            {...props}
            placeholder={placeholder}
          />
        </div>
      </div>
      <div
        className={classNames("mt-1 h-5", {
          hidden: !renderErrorContainer && !errorMessage,
        })}
      >
        {errorMessage && (
          <Typography component="h6" color="text-red700">
            {errorMessage}
          </Typography>
        )}
      </div>
    </span>
  );
}
