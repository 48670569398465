import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { HTMLAttributes, ReactNode } from "react";

import { FontSize, Option } from "../types";
import { Typography } from "../typography";

type OptionAttributes = HTMLAttributes<HTMLInputElement> & { disabled?: boolean };

type OptionalRadioGroupProps =
  | {
      options: Option[];
      children?: never;
      optionProps?: OptionAttributes;
      fontSize?: FontSize;
    }
  | { children: ReactNode; options?: never; optionProps?: never; fontSize?: never };

type Props<ValueType = string> = {
  value: ValueType;
  onChange: (value: ValueType) => void;
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "onChange">;
};

export type RadioGroupProps<ValueType = string> = Props<ValueType> & OptionalRadioGroupProps;

export function RadioGroup<ValueType = string>({
  value,
  onChange,
  options,
  containerProps,
  optionProps,
  fontSize,
  children,
}: RadioGroupProps<ValueType>) {
  return (
    <HeadlessRadioGroup
      data-testid="radio-group"
      value={value}
      onChange={onChange}
      className="flex"
      {...containerProps}
    >
      {options &&
        options.map(({ value, label }) => (
          <RadioGroupOption
            key={value}
            value={value}
            label={label}
            optionProps={optionProps}
            fontSize={fontSize}
          />
        ))}
      {!options && children}
    </HeadlessRadioGroup>
  );
}

RadioGroup.Option = RadioGroupOption;

interface RadioGroupOptionProps extends Omit<Props, "containerProps" | "onChange"> {
  label?: ReactNode;
  optionProps?: OptionAttributes;
  fontSize?: FontSize;
  description?: string;
}

export function RadioGroupOption({
  value,
  optionProps,
  label,
  fontSize = "text-sm",
  description,
}: RadioGroupOptionProps) {
  const { className, disabled, ...otherProps } = optionProps || {};
  return (
    <HeadlessRadioGroup.Option
      key={value}
      value={value}
      className={classNames("outline-none", className, {
        "cursor-pointer": !disabled,
      })}
      disabled={disabled}
      {...otherProps}
    >
      {({ checked, disabled }) => (
        <RadioButton
          checked={checked}
          disabled={disabled}
          label={label}
          fontSize={fontSize}
          description={description}
        />
      )}
    </HeadlessRadioGroup.Option>
  );
}

type RadioButtonProps = {
  label?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  fontSize: FontSize;
  description?: string;
};

function RadioButton({ label, checked, disabled, fontSize, description }: RadioButtonProps) {
  return (
    <div className="flex items-center">
      <div
        className={classNames(
          "flex h-4 w-4 items-center justify-center rounded-full border border-primaryBlue500 shrink-0",
          {
            "border-primaryBlue500": checked && !disabled,
            "border-blueGray300": !checked || disabled,
            "bg-blueGray25": disabled,
          }
        )}
      >
        {checked && (
          <div
            className={classNames("h-2 w-2 rounded-full", {
              "bg-primaryBlue500": !disabled,
              "bg-blueGray300": disabled,
            })}
          />
        )}
      </div>
      {label && !description && (
        <HeadlessRadioGroup.Label
          as="span"
          className={classNames("ml-2 mt-0.5 leading-5.5 text-primaryBlue900", fontSize)}
        >
          {label}
        </HeadlessRadioGroup.Label>
      )}
      {label && description && (
        <div className="ml-2">
          <HeadlessRadioGroup.Label
            as="span"
            className={classNames("mt-0.5 leading-5.5 text-primaryBlue900", fontSize)}
          >
            {label}
          </HeadlessRadioGroup.Label>
          {description && (
            <Typography color="text-blueGray600" fontSize="text-xs">
              {description}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
