import classNames from "classnames";
import moment from "moment";
import { ReactNode } from "react";

import { ThemeColor } from "../types";
import DatePicker from "./DatePicker";
import { FormElement, FormElementProps } from "./FormElement";
import { FormLabel } from "./FormLabel";

interface Props {
  title: ReactNode;
  selectedDate: Date | null;
  onSelectDay: (day: Date) => void;
  disabled?: boolean;
  required?: boolean;
  layout?: "VERTICAL" | "HORIZONTAL";
  spanContent?: string;
  labelColor?: ThemeColor;
  elementSize?: FormElementProps["size"];
  errorMessage?: string;
}

export const FormDate = ({
  title,
  onSelectDay,
  selectedDate,
  disabled,
  required = true,
  layout = "VERTICAL",
  spanContent = "*",
  labelColor = "blueGray600",
  elementSize = "grid",
  errorMessage,
}: Props) => {
  return (
    <FormElement size={elementSize}>
      <div
        className={classNames("flex", {
          "flex-col": layout === "VERTICAL",
          "flex-row justify-between align-middle items-center": layout !== "VERTICAL",
        })}
      >
        <FormLabel size="xs" spacing={{ mb: 1 }} color={labelColor}>
          {title}
          {required && <span className="text-red700 ml-1">{spanContent}</span>}
        </FormLabel>
        <div
          className={classNames("inline-block", {
            "w-1/2": layout !== "VERTICAL",
          })}
        >
          <DatePicker
            input
            initialMonth={
              selectedDate
                ? new Date(
                    selectedDate.getUTCFullYear(),
                    selectedDate.getUTCMonth(),
                    selectedDate.getUTCDay()
                  )
                : new Date()
            }
            onDayClick={(e) => {
              onSelectDay(e.day);
            }}
            preselectedDay={selectedDate ? moment.utc(selectedDate).format("DD/MM/YYYY") : ""}
            inputProps={{
              required: true,
              fluid: true,
              placeholder: "Select Date",
              size: "REGULAR",
              disabled: disabled,
            }}
            errorMessage={errorMessage ? errorMessage : undefined}
          />
        </div>
      </div>
      {errorMessage && (
        <div
          className={classNames("flex -mb-4", {
            "flex-col": layout === "VERTICAL",
            "flex-row justify-between align-middle items-center": layout !== "VERTICAL",
          })}
        >
          <div />
          <span className="text-red700 text-xs">{errorMessage}</span>
        </div>
      )}
    </FormElement>
  );
};
