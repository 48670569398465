import classNames from "classnames";
import { ReactNode } from "react";

import { SpacingProps, ThemeColor } from "../types";
import { getSpacingClass } from "../utils";

interface FormLabelProps extends SpacingProps {
  children: ReactNode;
  size?: "xs" | "sm" | "base";
  color?: ThemeColor;
  id?: string;
}

export function FormLabel({
  children,
  size = "sm",
  color = "blueGray600",
  spacing,
  id,
}: FormLabelProps) {
  const leading = {
    xs: "4",
    sm: "6",
    base: "8",
  }[size];

  return (
    <div
      className={classNames(
        `m-0 p-0 font-medium`,
        `text-${color} text-${size} leading-${leading}`,
        getSpacingClass(spacing)
      )}
      id={id}
    >
      {children}
    </div>
  );
}
