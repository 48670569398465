import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { ComponentType, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { ArrowDownIcon, ArrowUpIcon, CheckIcon, IconProps } from "../icons";
import { DataAttributes, ValidTailwindSizes } from "../types";
import { convertDataAttributes } from "../utils";

export type SelectSizeVariant = "SMALL" | "MEDIUM" | "REGULAR";

const SIZE_VARIANT_MAPS: Record<SelectSizeVariant, string> = {
  SMALL: "px-2 rounded-sm h-7",
  MEDIUM: "px-2 rounded-sm h-8",
  REGULAR: "pl-4 pr-4 rounded h-10",
};

const TEXT_SIZE_VARIANT_MAPS: Record<SelectSizeVariant, string> = {
  SMALL: "text-xs",
  MEDIUM: "text-xs",
  REGULAR: "text-sm",
};

const ABSOLUTE_TOP_VARIANT_MAPS: Record<SelectSizeVariant, string> = {
  SMALL: "top-7",
  MEDIUM: "top-7",
  REGULAR: "top-10",
};

const ERROR_SIZE_VARIANT_MAPS: Record<SelectSizeVariant, string> = {
  SMALL: "text-2xs",
  MEDIUM: "text-2xs",
  REGULAR: "text-xs",
};

const WIDTH_VARIANT_MAPS: Record<SelectSizeVariant, `w-${ValidTailwindSizes}`> = {
  SMALL: "w-40",
  MEDIUM: "w-40",
  REGULAR: "w-60",
};

// When refactor notice that this interface is used in DropdownSearch as well
export interface SelectOption {
  label: ReactNode;
  value: string;
  disabled?: boolean;
}

type SelectMultipleTypeProps =
  | {
      multiple: true;
      value: string[];
      onChange: (value: string[]) => void;
    }
  | {
      multiple?: false;
      value: string;
      onChange: (value: string) => void;
    };

export type SelectProps = {
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: SelectSizeVariant;
  options?: SelectOption[];
  width?: `w-${ValidTailwindSizes}`;
  className?: string;
  dataAttributes?: DataAttributes;
  placeholder?: ReactNode;
  renderOption?: (option: SelectOption) => JSX.Element;
  boxShadow?: boolean;
  variant?: "REGULAR" | "FILTER";
  onSelectClick?: () => void;
  placeholderColor?: string;
  border?: boolean;
  iconProps?: IconProps;
  overrideDisabledStyle?: boolean;
  labelClassName?: string;
  biColorList?: boolean;
  leftIcon?: ComponentType<IconProps> | undefined;
  leftIconSize?: number;
} & SelectMultipleTypeProps;

export const getMultipleSelectText = (value: ReactNode[]): ReactNode => {
  if (!value.length) {
    return "";
  }

  if (value.length <= 2) {
    return value.join(", ");
  }

  return (
    <FormattedMessage
      defaultMessage="{firstElement} and {restCount} more"
      values={{
        firstElement: value[0],
        restCount: value.length - 1,
      }}
      id="QYsAJ7"
    />
  );
};

export function Select({
  options,
  value,
  onChange,
  disabled,
  size = "REGULAR",
  error,
  errorMessage,
  width,
  className,
  dataAttributes = {},
  placeholder,
  renderOption,
  boxShadow,
  variant = "REGULAR",
  onSelectClick,
  border = true,
  iconProps,
  placeholderColor,
  overrideDisabledStyle,
  labelClassName = "",
  biColorList = true,
  multiple,
  leftIcon: LeftIconComponent,
  leftIconSize,
}: SelectProps) {
  const dataAttr = convertDataAttributes(dataAttributes);
  const selectedText = multiple
    ? getMultipleSelectText(
        options?.filter((option) => value.indexOf(option.value) !== -1).map((v) => v.label) || []
      )
    : options?.find((option) => option.value === value)?.label;

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled} multiple={Boolean(multiple)}>
      {({ open }) => (
        <div className={classNames("relative", width || WIDTH_VARIANT_MAPS[size])} {...dataAttr}>
          <Listbox.Button
            onClick={onSelectClick}
            className={classNames(
              `text-left text-blueGray600 focus:outline-none focus:ring-1 flex w-full cursor-pointer items-center justify-between bg-white`,
              SIZE_VARIANT_MAPS[size],
              TEXT_SIZE_VARIANT_MAPS[size],
              className,
              {
                border,
                "text-blueGray900 bg-red10 border-red500 hover:border-red10 focus:border-red10 ring-red10":
                  error,
                "border-blueGray200 hover:border-blueGray500 focus:border-primaryBlue500 ring-primaryBlue500":
                  !error && !disabled && !open,
                "border-primaryBlue500 ring-1 ring-primaryBlue500": open,
                "bg-blueGray50 border-blueGray200 cursor-not-allowed":
                  disabled && !overrideDisabledStyle,
                "cursor-not-allowed": disabled && overrideDisabledStyle,
                "shadow-1": boxShadow,
                "bg-white": variant === "REGULAR",
                "bg-blueGray25": variant === "FILTER",
              }
            )}
          >
            <div
              className={classNames("flex-1 pr-1 truncate", {
                "text-primaryBlue900":
                  selectedText && !placeholderColor && !labelClassName && !disabled,
                placeholderColor,
                "text-tint70": variant === "FILTER",
                "text-blueGray500": disabled,
                [labelClassName]: labelClassName,
              })}
            >
              {LeftIconComponent && (
                <LeftIconComponent
                  className={classNames("absolute top-1/2 -translate-y-1/2")}
                  size={leftIconSize || 16}
                />
              )}
              <div className={classNames({ "pl-6": LeftIconComponent })}>
                {selectedText || placeholder || (
                  <FormattedMessage defaultMessage="Select" id="kQAf2d" />
                )}
              </div>
            </div>
            {open
              ? ArrowUpIcon && (
                  <ArrowUpIcon size={14} className="text-primaryBlue900" {...iconProps} />
                )
              : ArrowDownIcon && (
                  <ArrowDownIcon size={14} className="text-primaryBlue900" {...iconProps} />
                )}
          </Listbox.Button>
          {error && errorMessage && (
            <div
              className={classNames(
                `flex-1 mt-1 text-red500 truncate ${ERROR_SIZE_VARIANT_MAPS[size]}`
              )}
            >
              {errorMessage}
            </div>
          )}
          <div
            className={`absolute z-20 mt-1 w-auto min-w-full rounded-md bg-white text-left shadow-lg ${ABSOLUTE_TOP_VARIANT_MAPS[size]}`}
          >
            <Transition
              show={open}
              leave="transition duration-100 ease-in"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Listbox.Options
                static
                className="border-blueGray200 max-h-56 overflow-auto rounded-md border text-base focus:outline-none sm:text-sm"
                data-cy="select-options"
              >
                {options?.map((option, index) => (
                  <Listbox.Option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {({ active, selected, disabled }) => (
                      <div
                        className={classNames(
                          `flex relative justify-between items-center select-none ${SIZE_VARIANT_MAPS[size]} `,
                          {
                            [`bg-blueGray10 text-gray900`]: biColorList && index % 2 !== 0,
                            [`bg-white text-gray900`]: biColorList && index % 2 === 0,
                          },
                          !disabled ? "cursor-pointer" : "cursor-not-allowed"
                        )}
                      >
                        <div
                          className={classNames("flex flex-1 pr-4 truncate", {
                            [TEXT_SIZE_VARIANT_MAPS[size]]: size,
                            "text-blueGray600": !active && !selected && !disabled,
                            "text-primaryBlue500": active && !disabled,
                            "font-medium text-primaryBlue500": selected && !disabled,
                            "text-blueGray300": disabled,
                          })}
                        >
                          {typeof renderOption === "function" ? renderOption(option) : option.label}
                        </div>
                        {selected ? <CheckIcon size={16} className="text-primaryBlue500" /> : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
