import classNames from "classnames";
import { ReactNode } from "react";

export interface FormElementProps {
  size?: "xs" | "small" | "regular" | "large" | "grid" | "grid-compact";
  children: ReactNode;
}

export function FormElement({ children, size = "regular" }: FormElementProps) {
  return (
    <div
      className={classNames({
        "pt-2": size === "xs",
        "pt-4": size === "small",
        "pt-6": size === "regular",
        "pt-8": size === "large",
        "pt-4 pb-4 pr-6 pl-6": size === "grid",
        "pt-0 pb-4 pr-6 pl-6": size === "grid-compact",
      })}
    >
      {children}
    </div>
  );
}
