import { z, ZodTypeAny } from "zod";

export type FormFieldErrors = string[];
export type FormErrors = { [key: string]: FormFieldErrors };
export type FormValidator<T extends Record<string, ZodTypeAny>> = {
  field: (key: keyof T, val: any) => { success: boolean; error: FormFieldErrors };
  parse: (object: Record<keyof T, unknown>) => { success: boolean; errors: FormErrors };
};

const getFieldError = (result: z.SafeParseReturnType<any, any>, key: string | number | symbol) => {
  if ("error" in result) return result.error.flatten().fieldErrors[key] || [];
  else return [];
};

const getFormError = (result: z.SafeParseReturnType<any, any>) => {
  if ("error" in result) return (result.error.flatten().fieldErrors as FormErrors) || [];
  else return {};
};

export const buildValidator = <T extends Record<string, ZodTypeAny>>(
  schema: T
): FormValidator<T> => {
  return {
    field: (key: keyof T, val: unknown) => {
      const formValidation = z.object({ [key]: schema[key] }).safeParse({ [key]: val });
      return {
        success: formValidation.success,
        error: getFieldError(formValidation, key),
      };
    },
    parse: (object: Record<keyof T, unknown>) => {
      const result = z.object(schema).safeParse(object);
      return {
        success: result.success,
        errors: getFormError(result),
      };
    },
  };
};
