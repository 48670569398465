import classNames from "classnames";
import { ReactNode } from "react";

import { SelectOption } from "../select";
import { Select, SelectSizeVariant } from "../select/Select";
import { OnSelectSearechFilter, SelectSearch } from "../select/SelectSearch";
import { DataAttributes, ThemeColor } from "../types";
import { convertDataAttributes } from "../utils";
import { FormElement, FormElementProps } from "./FormElement";
import { FormLabel } from "./FormLabel";

interface SelectProps {
  title: ReactNode;
  onChange: (option: string) => void;
  value: string;
  placeholder?: string;
  options: SelectOption[] | undefined;
  size?: SelectSizeVariant;
  layout?: "VERTICAL" | "HORIZONTAL";
  spanContent?: string;
  labelColor?: ThemeColor;
  errorMessage?: string;
  elementSize?: FormElementProps["size"];
  dataAttributes?: DataAttributes;
  searchable?: boolean;
  onSearchFilter?: OnSelectSearechFilter;
}

export const FormSelect = ({
  title,
  onChange,
  value,
  placeholder,
  options,
  size = "REGULAR",
  layout = "VERTICAL",
  spanContent = "*",
  labelColor = "blueGray600",
  elementSize = "grid",
  errorMessage,
  dataAttributes = {},
  searchable = false,
  onSearchFilter,
}: SelectProps) => {
  const dataAttr = convertDataAttributes(dataAttributes);
  return (
    <FormElement size={elementSize}>
      <div
        className={classNames("flex", {
          "flex-col": layout === "VERTICAL",
          "flex-row justify-between align-middle items-center": layout !== "VERTICAL",
        })}
      >
        <FormLabel size="xs" spacing={{ mb: 1 }} color={labelColor}>
          {title}
          <span className="text-red700 ml-1">{spanContent}</span>
        </FormLabel>
        <div
          {...dataAttr}
          className={classNames("inline-block", {
            "w-1/2": layout !== "VERTICAL",
          })}
        >
          {!searchable && (
            <Select
              width="w-full"
              multiple={false}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              options={options}
              size={size}
              error={Boolean(errorMessage)}
            />
          )}

          {searchable && (
            <SelectSearch
              width="w-full"
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              options={options || []}
              size={size}
              error={Boolean(errorMessage)}
              onFilter={onSearchFilter}
            />
          )}
        </div>
      </div>
      {errorMessage && (
        <div
          className={classNames("flex -mb-4 overflow-visible", {
            "flex-col": layout === "VERTICAL",
            "flex-row justify-between align-middle items-center": layout !== "VERTICAL",
          })}
        >
          <div />
          <span className="text-red700 text-xs">{errorMessage}</span>
        </div>
      )}
    </FormElement>
  );
};
