import { ChangeEvent } from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldError,
  FieldPath,
  useFormContext,
  UseFormStateReturn,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";

import { Input, InputProps } from "../input";
import { Select, SelectProps } from "../select";
import { DatePicker, DatePickerProps, TextArea, TextAreaProps } from ".";

interface RHFInputProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  inputProps:
    | {
        placeholder: InputProps["placeholder"];
      }
    | InputProps;
  onChangeAction?: (event: ChangeEvent<HTMLInputElement>) => void;
  autoPassError?: boolean;
}

const getErrorProps = ({
  autoPassError,
  error,
}: {
  autoPassError?: boolean;
  error?: FieldError | undefined;
}) => {
  if (!autoPassError) {
    return {};
  }

  if (!error) {
    return {};
  }

  return {
    errorMessage: error.message,
    state: "ERROR" as const,
    iconVariant: "ALERT" as const,
  };
};

export function RHFInputField({
  name,
  controllerProps,
  inputProps,
  onChangeAction,
  autoPassError = true,
}: RHFInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
        <Input
          value={value}
          onChange={(event) => {
            onChange(event);
            onChangeAction && onChangeAction(event);
          }}
          {...inputProps}
          {...getErrorProps({ autoPassError, error })}
          onBlur={onBlur}
        />
      )}
      {...controllerProps}
    />
  );
}

interface RenderProps<T extends FieldValues> {
  field: ControllerRenderProps<T, FieldPath<T>>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}

interface RHFSelectProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  selectProps?: Omit<SelectProps, "value" | "onChange">;
  getRenderProps?: (
    renderProps: RenderProps<any>
  ) => Partial<Omit<SelectProps, "value" | "onChange">>;
  // TODO:
  //   Escape hatch for back compat, need to check all `RHFSelectField`
  //   callsites and make `autoPassError` default
  autoPassError?: boolean;
  disabled?: boolean;
}

export function RHFSelectField({
  name,
  controllerProps,
  selectProps,
  getRenderProps,
  autoPassError,
  disabled,
}: RHFSelectProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={(renderProps) => {
        const {
          field: { value, onChange },
          fieldState: { error },
        } = renderProps;
        return (
          <Select
            {...selectProps}
            disabled={disabled}
            value={value}
            onChange={onChange}
            {...getRenderProps?.(renderProps)}
            {...(autoPassError
              ? { error: Boolean(error?.message), errorMessage: error?.message }
              : {})}
          />
        );
      }}
      {...controllerProps}
    />
  );
}

interface RHFDateProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  datePickerProps?: Omit<DatePickerProps, "onDayClick" | "input">;
  autoPassError?: boolean;
}

export function RHFDateField({
  name,
  controllerProps,
  datePickerProps,
  autoPassError = true,
}: RHFDateProps) {
  const { control } = useFormContext();
  const { inputProps, ...otherDatePickerProps } = datePickerProps || {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <DatePicker
          initialMonth={new Date()}
          input={true}
          onDayClick={({ day }) => onChange(day)}
          inputProps={{
            ...inputProps,
            ...getErrorProps({ autoPassError, error }),
          }}
          {...otherDatePickerProps}
        />
      )}
      {...controllerProps}
    />
  );
}

interface RHFTextAreaProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  textAreaProps:
    | {
        placeholder: TextAreaProps["placeholder"];
      }
    | TextAreaProps;
  onChangeAction?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function RHFTextAreaField({
  name,
  controllerProps,
  textAreaProps,
  onChangeAction,
}: RHFTextAreaProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <TextArea
          value={value}
          onChange={(event) => {
            onChange(event);
            onChangeAction && onChangeAction(event);
          }}
          {...textAreaProps}
          onBlur={onBlur}
        />
      )}
      {...controllerProps}
    />
  );
}
